import React, { forwardRef, memo, ForwardedRef } from "react";
import { Action } from "src/theme/typography";
// theme
import { CheckboxContainer, CheckboxLabel } from "./checkbox.styles";
// types
import { ICheckBox } from "./checkbox.types";

const Checkbox = (
  { label, name, wrapperStyle, checkboxStyle, value, ...rest }: ICheckBox,
  ref: ForwardedRef<any>,
) => {
  return (
    <CheckboxLabel htmlFor={name} isActive={value} style={wrapperStyle}>
      <input
        ref={ref}
        style={{ display: "none" }}
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        {...rest}
      />
      <CheckboxContainer isActive={value} style={checkboxStyle} />
      <Action>{label}</Action>
    </CheckboxLabel>
  );
};

export default memo(forwardRef(Checkbox));
