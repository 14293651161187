// libs
import styled, { css } from "styled-components";
// themes
import { colors, typography } from "src/theme";

interface IActiveCSS {
  isActive: boolean;
}

export const CheckboxContainer = styled.div<IActiveCSS>`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: ${({ isActive }) => (isActive ? "none" : `2px solid ${colors.greyLight}`)};
  margin-right: 10px;
  position: relative;
`;

const CheckedStyles = css`
  input + div::before {
    content: "";
    background-image: url("/icons/checkbox-blue.svg");
    background-repeat: no-repeat;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    background-size: cover;
    display: flex;
    align-self: center;
  }
`;

export const CheckboxLabel = styled.label<IActiveCSS>`
  user-select: none;
  border: ${({ isActive }) =>
    isActive ? "2px solid transparent" : `2px solid ${colors.greyVeryLight}`};
  border-radius: 8px;
  padding: 10px 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0;
  color: ${colors.greyDark};
  white-space: nowrap;
  transition: 0.3s all ease;
  background-color: ${({ isActive }) => (isActive ? colors.mainLight : colors.white)};
  max-width: fit-content;

  :hover {
    ${({ isActive }) => !isActive && `background-color: ${colors.mainVeryLight}`};
  }

  ${({ isActive }) => isActive && CheckedStyles}
`;
