import React, { FC, useEffect } from "react";
// libs
import Modal from "react-modal";
import NextImage from "next/image";
import Router from "next/router";
import axios from "src/libs/axios";
// components
import Button from "src/components/ui/button";
import Checkbox from "src/components/ui/Checkbox";
// const
import { SEARCH_FILTERS_MODAL } from "src/constants/modals";
import { ENDPOINTS } from "src/constants/endpoints";
// hooks
import { useForm, Controller } from "react-hook-form";
import { useAppSelector, useModal, useWindowSize } from "src/hooks";

import useSWR from "swr";
// redux
import { modalSelector } from "src/redux/slices";
// theme
import {
  ButtonsWrapper,
  ButtonsMobileWrapper,
  HeaderWebWrapper,
  HeaderMobileWrapper,
  Title,
  Wrapper,
  CloseWrapper,
  FormWrapper,
  SectionTitle,
  Divider,
  ButtonContainer,
} from "./searchFilter.styles";
// utils
import { getCountry } from "src/utils/countries";
// types
import { IModalSearchFilterProps } from "./searchFilter.types";
import { EVariants } from "src/constants/variants";
import { IService } from "src/types";

const ModalSearchFilter: FC<IModalSearchFilterProps> = () => {
  const { width } = useWindowSize();
  const { closeModal } = useModal();
  const { isOpen } = useAppSelector(modalSelector(SEARCH_FILTERS_MODAL));
  const { control, handleSubmit, setValue, getValues } = useForm();

  const country = getCountry(Router.router?.locale, "locale")?.name;
  const servicesResponse = useSWR(
    `${ENDPOINTS.GET.services}?_sort=name:asc&_limit=30&clinics.country=${country}`,
    axios,
  );

  const params = { ...Router.router?.query };

  const getSelectedFiltersByField = (field: string): string[] =>
    (Array.isArray(params[field]) && (params[field] as string[])) ||
    (typeof params[field] === "string" && ([params[field]] as string[])) ||
    [];

  const updateValuesFromUrl = () => {
    const data = getValues();

    for (const field in data) {
      const serviceFromUrlEntity = params.area?.includes("service") ? [params.area?.[1]] : null;
      const selectedFiltersByField = serviceFromUrlEntity || getSelectedFiltersByField(field);
      const keys = Object.keys(data[field]);
      keys.forEach((key) => setValue(`${field}.${key}`, selectedFiltersByField.includes(key)));
    }
  };

  const handleCloseModal = () => {
    closeModal(SEARCH_FILTERS_MODAL);
  };

  useEffect(() => {
    updateValuesFromUrl();
  }, [params]);

  const updateURL = async (data: any) => {
    for (const field in data) {
      let selectedFiltersByField = getSelectedFiltersByField(field);

      const keys = Object.keys(data[field]);
      const negativeKeys: string[] = keys.reduce<string[]>(
        (acc, key) =>
          selectedFiltersByField.includes(key) && !data[field][key] ? [...acc, key] : acc,
        [],
      );
      const positiveKeys: string[] = keys.reduce<string[]>(
        (acc, key) =>
          !selectedFiltersByField.includes(key) && data[field][key] ? [...acc, key] : acc,
        [],
      );

      if (negativeKeys.length) {
        selectedFiltersByField = selectedFiltersByField.filter(
          (key) => !negativeKeys.includes(key),
        );
      }

      if (positiveKeys.length) {
        selectedFiltersByField = [...selectedFiltersByField, ...positiveKeys];
      }

      params[field] = selectedFiltersByField;
    }

    params._start = "0";
    if (Router.query.area?.includes("service" || "search") || Router.query.geo_order)
      params.area = ["search"];

    await Router.push(
      {
        pathname: Router.router?.pathname,
        query: params,
      },
      undefined,
      { shallow: true },
    );
  };

  const onSubmit = async (data: any) => {
    await updateURL(data);
    handleCloseModal();
  };

  const getDefaultValue = (fieldName: string, label: string): boolean => {
    const fieldArr =
      (Array.isArray(params[fieldName]) && params[fieldName]) ||
      (params[fieldName] && [params[fieldName]]) ||
      [];
    return fieldArr.includes(label);
  };

  const resetValues = async () => {
    const data: any = {};

    const values = getValues();
    const fields = Object.keys(values);
    fields.forEach((field) => (data[field] = {}));

    for (const field in values) {
      const keysByField = Object.keys(values[field]);
      keysByField.forEach((key) => {
        data[field][key] = false;
        setValue(`${field}.${key}`, false);
      });
    }

    await updateURL(data);
  };

  const overlay = {
    zIndex: 3000,
    backgroundColor: "rgba(46, 46, 46, 0.75)",
  };

  let content: { [key: string]: string } = {
    padding: "0px",
    border: "none",
    borderRadius: "8px",
    width: "100vw",
    top: "0px",
    left: "0px",
    bottom: "0px",
    right: "0px",
  };

  if (width >= 768) {
    content = {
      ...content,
      width: "650px",
      top: "5%",
      bottom: "5%",
      left: "50%",
      transform: "translateX(-50%)",
    };
  }

  const renderControlledField = (key: number | string, fieldName: string, label: string) => (
    <Controller
      key={key}
      name={`${fieldName}.${label}`}
      control={control}
      defaultValue={getDefaultValue(fieldName, label)}
      render={({ field }) => <Checkbox label={label.replace("-", " ")} {...field} />}
    />
  );

  const servicesToRender = (servicesResponse.data?.data || [])
    .map((service: IService) => ({ id: service.id, name: service.name.replace(" ", "-") }))
    .filter(
      (service: { id: IService["id"]; name: IService["name"] }) => service.id && service.name,
    );

  return (
    <Modal
      isOpen={isOpen}
      style={{ overlay, content }}
      contentLabel="Search Filters modal"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
    >
      <Wrapper>
        <HeaderWebWrapper>
          <Title>Add filters</Title>

          <ButtonsWrapper>
            <Button variant={EVariants.SECONDARY} onClick={resetValues}>
              Reset
            </Button>
            <CloseWrapper onClick={handleCloseModal} margin="0 0 0 16px">
              <NextImage src="/icons/close-black.svg" width={12} height={12} />
            </CloseWrapper>
          </ButtonsWrapper>
        </HeaderWebWrapper>

        <HeaderMobileWrapper>
          <ButtonsMobileWrapper>
            <CloseWrapper onClick={handleCloseModal} margin="0">
              <NextImage src="/icons/arrow-left-black.svg" width={13.5} height={13.5} />
            </CloseWrapper>

            <Button variant={EVariants.SECONDARY} onClick={resetValues}>
              Reset
            </Button>
          </ButtonsMobileWrapper>

          <Title>Add filters</Title>
        </HeaderMobileWrapper>

        <FormWrapper>
          {/* {['Recommended'].map((item, idx) => renderControlledField(idx, item))} */}

          {/* <Divider /> */}

          {/* <SectionTitle>Pets & Animals</SectionTitle> */}
          {/* {['Equestrian', 'Farm', 'Small', 'Wildlife'].map((item, idx) => renderControlledField(idx, item))} */}

          {/* <Divider /> */}

          {/* <SectionTitle>Facilities</SectionTitle> */}
          {/* {['On-site parking'].map((item, idx) => renderControlledField(idx, item))} */}

          {/* <Divider /> */}

          <SectionTitle>Services</SectionTitle>
          {servicesToRender.map(({ id, name }: { id: IService["id"]; name: IService["name"] }) =>
            renderControlledField(id, "services", name),
          )}

          <ButtonContainer>
            <Button
              variant={EVariants.PRIMARY}
              onClick={handleSubmit(onSubmit)}
              style={{ width: width < 768 ? "100%" : "auto" }}
            >
              Show results
            </Button>
          </ButtonContainer>
        </FormWrapper>
      </Wrapper>
    </Modal>
  );
};

export default ModalSearchFilter;
