import React, { FC } from "react";
// libs
import Modal from "react-modal";
import NextImage from "next/image";
import { useRouter } from "next/router";
// components
import Link from "src/components/ui/Link";
import Button from "src/components/ui/button";
// hooks
import { useAppSelector, useModal } from "src/hooks";
// redux
import { dynamicStateFieldSelector, modalSelector } from "src/redux/slices";
// theme
import { InnerWrapper, HeaderWrapper, ContentWrapper } from "./menu.styles";
// const
import { MENU_MODAL } from "src/constants/modals";
import { HEADER } from "src/constants/dynamic";
// types
import { IModalMenuProps } from "./menu.types";
import { EVariants } from "src/constants/variants";
import { IDynamicHeader, IDynamicHeaderHeaderLinks } from "src/types/dynamic/header";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
import { Body, Caption } from "src/theme/typography";
import { colors } from "src/theme";

const Menu: FC<IModalMenuProps> = () => {
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicHeader>(HEADER));
  const { pathname } = useRouter();
  const { isOpen } = useAppSelector(modalSelector(MENU_MODAL));
  const { closeModal } = useModal();

  const handleCloseModal = () => closeModal(MENU_MODAL);

  const overlay = {
    zIndex: 3000,
    backgroundColor: "rgba(46, 46, 46, 0.75)",
  };

  let content: { [key: string]: string } = {
    padding: "10px 24px",
    border: "none",
    borderRadius: "0",
    top: "0px",
    left: "0px",
    bottom: "0px",
    right: "0px",
  };
  // TODO: 1. make single type in Strapi for mobile menu
  // TODO: 2. load mobile menu to redux store - dynamic field
  // TODO: 3. integrate here

  return (
    <Modal
      isOpen={isOpen}
      style={{ overlay, content }}
      contentLabel="Menu modal"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
    >
      <InnerWrapper>
        <HeaderWrapper>
          <NextImage src={"/logo/logo.svg"} width={58} height={47} />
          <Button
            variant={EVariants.SECONDARY}
            onClick={handleCloseModal}
            style={{ maxWidth: "fit-content" }}
          >
            Close
          </Button>
        </HeaderWrapper>
        <ContentWrapper>
          <Link
            href="/"
            anchorProps={{
              onClick: handleCloseModal,
            }}
          >
            <Body textColor={colors.greyDark} style={{ padding: 10 }}>
              Home
            </Body>
          </Link>
          {(data?.header_links || []).map((item: IDynamicHeaderHeaderLinks) => (
            <Link
              key={item.id}
              href={item.url}
              anchorProps={{
                onClick: handleCloseModal,
              }}
            >
              <Body textColor={colors.greyDark} style={{ padding: 10 }}>
                {item.title}
              </Body>
            </Link>
          ))}

          {pathname === "/clinics" && (
            <Link
              href="/demo"
              anchorProps={{
                onClick: handleCloseModal,
              }}
            >
              <Button variant={EVariants.PRIMARY}>Book a demo</Button>
            </Link>
          )}

          <Link href="https://app.petsapp.com/">
            <Button
              variant={EVariants.SECONDARY}
              onClick={() => amplitudeEvent(formatEventName(pathname, "nav", "sign_in_pet_owner"))}
            >
              Sign in as a pet owner
            </Button>
          </Link>

          <Link href="https://dashboard.petsapp.com/">
            <Button
              variant={EVariants.SECONDARY}
              onClick={() =>
                amplitudeEvent(formatEventName(pathname, "nav", "sign_in_clinic_team_member"))
              }
            >
              Sign in as a clinic team member
            </Button>
          </Link>

          <Link href="/">
            <Caption style={{ padding: 14, marginBottom: -12 }}>Terms and conditions</Caption>
          </Link>
          <Link href="/">
            <Caption style={{ padding: 14 }}>Privacy policy</Caption>
          </Link>
        </ContentWrapper>
      </InnerWrapper>
    </Modal>
  );
};

export default Menu;
