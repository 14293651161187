// libs
import styled from "styled-components";
// theme
import { typography, colors } from "src/theme";
// const
import devices from "src/constants/media";

export const Wrapper = styled.div`
  padding: 25px;
`;

export const HeaderWebWrapper = styled.div`
  display: none;

  @media ${devices.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HeaderMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    display: none;
  }
`;

export const Title = styled.h3`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.greyDark};
  margin: 28px 0 32px;

  @media ${devices.tablet} {
    margin: 0;
    margin-bottom: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsMobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseWrapper = styled.div<{ margin: string }>`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.greyVeryLight};
  border-radius: 50%;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  :hover {
    background-color: ${colors.mainVeryLight};
  }
`;

export const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    margin: 4px 0;
  }

  @media (min-width: 501px) {
    button {
      margin-top: 32px;
    }
  }
`;

export const SectionTitle = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.grey};
  margin-bottom: 12px;
`;

export const Divider = styled.div`
  margin: 16px 0;
  border: 1px solid ${colors.greyVeryLight};
  width: 100%;
`;
export const ButtonContainer = styled.div`
  @media (max-width: 500px) {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 25px 0;
    background: ${colors.white};
  }
`;
