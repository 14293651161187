import React, { FC, useEffect } from "react";
// libs
import Modal from "react-modal";
import NextImage from "next/image";
// components
import Button from "src/components/ui/button";
// const
import { GEOLOCATION_MODAL } from "src/constants/modals";
import { EVariants } from "src/constants/variants";
import { GEOLOCATION } from "src/constants/permissions";
import { LAST_LOCATION_MODAL_SHOW } from "src/constants/storage";
import { HIDE_GEOLOCATION_MODAL_EVERYWHERE } from "src/constants/variables";
// hooks
import { useAppDispatch, useAppSelector, useModal, useUserGeolocation } from "src/hooks";
// redux
import { authUserGeometrySelector, modalSelector, updatePermission } from "src/redux/slices";
import { permissionSelector } from "src/redux/slices";
import { updateVariable } from "src/redux/slices/variables";
// types
import { IGeometry } from "src/constants/coordinates";
// theme
import { Title, Wrapper } from "./geolocation.styles";

const ModalGeolocation: FC = () => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const { isOpen, onSuccess, onDeny, onCloseCb } = useAppSelector(modalSelector(GEOLOCATION_MODAL));
  const { getUserCoordinates } = useUserGeolocation();
  const geolocationPermission = useAppSelector(permissionSelector(GEOLOCATION));
  const userGeometry: IGeometry = useAppSelector(authUserGeometrySelector);

  const setIsModalShown = () => {
    dispatch(updateVariable({ name: HIDE_GEOLOCATION_MODAL_EVERYWHERE, value: true }));
    const currentDate = new Date();
    localStorage.setItem(LAST_LOCATION_MODAL_SHOW, currentDate.toString());
  };

  const handleCloseModal = () => {
    closeModal(GEOLOCATION_MODAL);
    onCloseCb?.(userGeometry);
  };

  const handleSuccess = (geometry: IGeometry) => {
    onSuccess?.(geometry);
    handleCloseModal();
  };

  const handleDeny = (geometry: IGeometry) => {
    onDeny?.(geometry);
    handleCloseModal();
  };

  const handleUseLocation = () => {
    if (geolocationPermission === "granted") {
      handleSuccess(userGeometry);
      setIsModalShown();
    } else if (geolocationPermission === "denied") {
      handleDeny(userGeometry);
      setIsModalShown();
    } else {
      getUserCoordinates(handleSuccess, handleDeny);
    }
  };

  const handleDenyLocation = () => {
    handleCloseModal();
    setIsModalShown();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 3000,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          padding: "32px 48px 24px",
          width: "100%",
          maxWidth: "480px",
          height: "480px",
          border: "none",
          borderRadius: 8,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="Map modal"
      ariaHideApp={false}
    >
      <Wrapper>
        <Title>Use your location to find your nearest clinic?</Title>

        <NextImage src="/icons/map_location.png" width={180} height={180} />

        <Button
          variant={EVariants.PRIMARY}
          onClick={handleUseLocation}
          style={{ width: "100%", marginTop: 32 }}
        >
          {geolocationPermission === "loading" ? "Loading..." : "Use my location"}
        </Button>

        <Button
          variant={EVariants.SECONDARY}
          onClick={handleDenyLocation}
          style={{ width: "100%", marginTop: 12 }}
        >
          No thanks
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default ModalGeolocation;
