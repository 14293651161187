// libs
import styled from "styled-components";
// theme
import { typography, colors } from "src/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h6`
  margin-bottom: 32px;
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${colors.grey};
`;
